import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/proposal';


export const getManifest = (proposalId) => {

	let url = `${apiRoot}/GetManifest/${proposalId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}


export const submit = (data) => {

	let url = `${apiRoot}/Submit`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
}

