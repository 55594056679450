import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/Customer';


export const getDetails = (customerId) => {

	let url = `${apiRoot}/${customerId}`;

	const options = {
		method: API_METHOD.GET
	};
	return handleFetch(url, options, true);
}

