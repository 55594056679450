import { PROPOSAL as ActionTypes } from '../actionTypes';



const initialState = {
	proposal: {
		proposalId: '',
		batchId: 0,
		statusId: 1,
		status: 'Sent',
		agentId: 0,
		agentName: '',
		agentLastName: '',
		agentEmail: '',
		dobNowEmail: '',
		auditDobNowEmail: '',
		customerId: 0,
		customerName: '',
		agreedToTermsAndConditions: '',
		year: '',
		superName: '',
		auditSuperName: '',
		dateCreated: '',
		isPeriodic: false
	},
	proposalDetails: {
		proposalDetailsId: 0,
		proposalId: '',
		statusId: 0,
		status: '',
		inspectionId: 0,
		lineId: 0,
		buildingId: 0,
		buildingAddress: '',
		corpName: '',
		auditCorpName: '',
		elevatorCompanyId: 0,
		elevatorCompany: '',
		auditElevatorCompany: '',
		trackAoc: 0,
		auditTrackAoc: '',
		superName: '',
		auditSuperName: '',
		superPhone: '',
		auditSuperPhone: '',
		device: '',
		year: '',
		agentId: 0,
		agentEmail: '',
		dateCreated: '',
		isPeriodic: false
	},
	lines: []
};

export const proposalReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_PROPOSAL_DETAIL:

			return {
				...state,
				proposalDetails: action.payload
			};
		case ActionTypes.SET_PROPOSAL:

			return {
				...state,
				proposal: action.payload.proposal,
				lines: action.payload.lines
			};


		case ActionTypes.SET_LINES:
			// used for approve all or reject all
			return { ...state, lines: action.payload };


		case ActionTypes.UPDATE_LINE:
			let newArray = [...state.lines];
			let index = state.lines.findIndex(c => c.proposalDetailsId == action.payload.proposalDetailsId);
			newArray[index] = action.payload;
			return { ...state, lines: newArray };

		case ActionTypes.UPDATE_PROPOSAL:
			// used to update the status of the prop from sent to viewed and from viewed to submitted
			return {
				...state,
				proposal: action.payload
			};


		default:

			return state;
	};
}