export const VERSION = '11.17.23.107';
export const API_VERSION = '11.17.23.204';

export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5261/api',

	// dev azure
	API: 'https://sierra-proposal-api-dev.azurewebsites.net/api',

	// prod azure
	//API: 'https://sierra-proposal-api-prod.azurewebsites.net/api',
};

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	PUT: 'PUT'
};

export const AUTH_HEADER = {
	AUTH: {
		'Authorization': null,
		'Content-Type': 'application/json; charset=utf-8'
	},
	NO_AUTH: {
		'Content-Type': 'application/json; charset=utf-8'
	}
};

export const BUTTON_ACTIONS = {
	INSERT: 'Insert',
	UPDATE: 'Update',
	EDIT: 'Edit',
	DELETE: 'Delete'
};