import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, FormGroup, Label } from 'reactstrap';

// components

// services

import * as newProposalActions from '../../redux/actions/newProposalActions';

const DeviceModal = ({ showModal, cancelModal }) => {
	let dispatch = useDispatch();
	const { proposalDetails } = useSelector(state => state.newProposalReducer);
	const [localDetails, setLocalDetails] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isAocActive, setIsAocActive] = useState(false);
	const [isQceActive, setIsQceActive] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	useEffect(() => {
		setLocalDetails(proposalDetails);
		if (proposalDetails.auditTrackAoc === true) {
			setIsAocActive(true);
		}

		if (proposalDetails.auditQce === true) {
			setIsQceActive(true);
		}

	}, []);

	const submitModal = () => {

		if (formIsDifferent()) {
			// update redux and close

			dispatch(newProposalActions.updateNewLine(localDetails));
		}

		cancelModal();
	}

	const formIsDifferent = () => {

		//let tempAoc = false; // means not checked
		//if (isAocActive === true) tempAoc = 1;
		localDetails.auditTrackAoc = isAocActive;

		// if periodic then don't check the qce field for changes
		if (proposalDetails.isPeriodic === true) {

			if (
				localDetails.corpName !== localDetails.auditCorpName ||
				localDetails.elevatorCompany !== localDetails.auditElevatorCompany ||
				localDetails.superName !== localDetails.auditSuperName ||
				localDetails.superPhone !== localDetails.auditSuperPhone ||
				localDetails.trackAoc !== localDetails.auditTrackAoc
			) {

				return true;
			}

		} else {

			// we have a category, check the qce for changes
			localDetails.auditQce = isQceActive;
			if (
				localDetails.corpName !== localDetails.auditCorpName ||
				localDetails.elevatorCompany !== localDetails.auditElevatorCompany ||
				localDetails.superName !== localDetails.auditSuperName ||
				localDetails.superPhone !== localDetails.auditSuperPhone ||
				localDetails.trackAoc !== localDetails.auditTrackAoc ||
				localDetails.qce !== localDetails.auditQce
			) {

				return true;
			}
		}

		return false;
	}

	const closeModal = () => {
		cancelModal();
	}


	return (
		<>
			<Modal isOpen={showModal} centered style={{ maxWidth: 950 }}>
				<ModalHeader>Update Device Line</ModalHeader>
				<ModalBody>
					{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}

					<div className="form-row">
						<div className="col-6"><h5>{proposalDetails.buildingAddress}</h5></div>
						<div className="col-6 text-right"><h5>{proposalDetails.device}</h5></div>
					</div>
					<div className="form-row">
						<div className="col-6">
							<FormGroup >
								<Label>Corp Building Name</Label>
								<Input
									placeholder='Corp Building Name'
									autoComplete='nofill'
									value={(localDetails && localDetails.auditCorpName) ? localDetails.auditCorpName : ''}
									onChange={({ target }) => setLocalDetails({ ...localDetails, auditCorpName: target.value })}
								/>
							</FormGroup>

							<FormGroup>
								<Label>Elevator Company</Label>
								<Input
									placeholder='Elevator Company'
									autoComplete='nofill'
									value={(localDetails && localDetails.auditElevatorCompany) ? localDetails.auditElevatorCompany : ''}
									onChange={({ target }) => setLocalDetails({ ...localDetails, auditElevatorCompany: target.value })}
								/>
							</FormGroup>
							<FormGroup >
								<Label>AOC Tracking</Label>
								<CustomInput
									type="switch"
									id="aoc"
									label=""
									checked={isAocActive}
									onChange={({ target }) => setIsAocActive(target.checked)}
								/>
							</FormGroup>

						</div>
						<div className="col-6">
							<FormGroup>
								<Label>Super Name</Label>
								<Input
									placeholder='Super Name'
									autoComplete='nofill'
									value={(localDetails && localDetails.auditSuperName) ? localDetails.auditSuperName : ''}
									onChange={({ target }) => setLocalDetails({ ...localDetails, auditSuperName: target.value })}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Super Phone</Label>
								<Input
									placeholder='Super Phone'
									autoComplete='nofill'
									value={(localDetails && localDetails.auditSuperPhone) ? localDetails.auditSuperPhone : ''}
									onChange={({ target }) => setLocalDetails({ ...localDetails, auditSuperPhone: target.value })}

								/>
							</FormGroup>

							{proposalDetails.isPeriodic === false && <FormGroup >
								<Label>QCE</Label>
								<CustomInput
									type="switch"
									id="qce"
									label=""
									checked={isQceActive}
									onChange={({ target }) => setIsQceActive(target.checked)}
								/>
							</FormGroup>}


						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" style={{ marginRight: 10 }} onClick={submitModal} disabled={disableButton}>Update</Button>
					<Button color="info" onClick={closeModal}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default DeviceModal;