import { API_METHOD, ENDPOINTS } from './appConstants';
import { handleFetch } from './handleFetch';


const apiRoot = ENDPOINTS.API + '/version';


export const getApiVersion = () => {

    let url = `${apiRoot}/GetVersion`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
}