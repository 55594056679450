import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import Footer from "../common/Footer";
import Logo from "../common/Logo";
import Section from "../common/Section";

//import AuthBasicRoutes from '../components/auth/basic/AuthBasicRoutes';

const ExternalLayout = (props) => {

  const getLocation = () => {
    //console.log(window.location.pathname);


  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <Section className="py-0">
      <Row className="flex-center py-4">
        <Col md={12}>
          {/* <Logo location={"auth"} logo={logo} /> */}

          {props.children}
          <Footer />
        </Col>
      </Row>
    </Section>
  );
};

export default ExternalLayout;
