import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Alert, Card, CardBody, CardTitle } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/es/Badge';

// components
import Logo from "../common/Logo";

// services
import * as inspectionApi from '../services/inspectionApi';
import * as customerApi from '../services/customerApi';


const CustomerViolationReport = () => {

	let { id, year } = useParams();
	let history = useHistory();
	const [logo, setLogo] = useState(require("../assets/img/sierra.png"));


	const [showLoader, setShowLoader] = useState(true);
	const [violationReport, setViolationReport] = useState([]);
	const [customerDetails, setCustomerDetails] = useState({});

	useEffect(() => {
		loadReport();
	}, []);

	const loadReport = () => {

		// if (!id) {
		// 	// we don't have a
		// 	history.push('/notFound');
		// }

		let d = new Date();
		let n = d.getFullYear();

		// get customer
		customerApi.getDetails(id)
			.then(resp => {
				setCustomerDetails(resp.data);
			});

		inspectionApi.getManifest(id, year)
			.then(resp => {
				setViolationReport(resp.data);
				setShowLoader(false);
			});
	}

	const columns = [
		{
			dataField: 'part',
			text: 'Part',
			classes: 'py-2 align-middle',
			sort: true,
			//formatter: corpNameFormat,
			headerStyle: (colum, colIndex) => {
				return { width: "40%", textAlign: "left" };
			}
		},
		{
			dataField: 'violation',
			text: 'Violation',
			classes: 'py-2 align-middle',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "40%", textAlign: "left" };
			},
		},

		{
			dataField: 'total',
			text: 'Count',
			classes: 'py-2 align-middle',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "20%", textAlign: "left" };
			}
		}
	];

	const renderRow = violationReport.map((item, index) => {
		return (
			<div key={index}>
				<Row noGutters >
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Row className="align-items-center">
									<Col className="col-6 text-left  py-2">
										<h5>{item.elevatorCompanyName}</h5>
										{/* <h6>Please review the list below, edit as needed and approve or decline.</h6> */}
									</Col>
									<Col className="col-6 text-right py-2">

									</Col>
								</Row>
								<div className="table-responsive">
									{(item.deficiencySummary.length !== 0) &&
										<BootstrapTable
											bootstrap4
											keyField="myIndex"
											data={item.deficiencySummary}
											columns={columns}
											bordered={false}
											classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
											rowClasses="btn-reveal-trigger"
											headerClasses="bg-200 text-900"
										/>
									}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	});


	return (
		<>
			{showLoader === true ? (
				<>
					<Row noGutters>
						<Col xl={12}>
							<Card className="mb-3">
								<CardBody>

									<Row className="m-5">
										<Col xs="12" style={{ alignSelf: 'center', textAlign: 'center' }}>
											<h3>Preparing your report.  One moment please......</h3>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>


				</>

			) : (<>
				<Row noGutters>
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Logo location={"auth"} logo={logo} />

								<Row className="m-2">
									<Col>
										<h5 className="text-center">{year} INSPECTION ANALYSIS REPORT</h5>
									</Col>
								</Row>
								{/* <Row className="m-2">
									<Col>
										<p className="text-center">
											description of the report here
									</p>
									</Col>
								</Row> */}


								<Row>
									<Col xl={12}>
										<div className="text-center">
											<h4>{customerDetails.customerName}</h4>
										</div>
									</Col>

								</Row>
							</CardBody>

						</Card>
					</Col>
				</Row>
				{violationReport.length !== 0 && renderRow}


			</>)}

		</>
	);
};

export default CustomerViolationReport;
