import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import toggleStylesheet from './helpers/toggleStylesheet';
import Routes from './routes';

import { useSelector } from 'react-redux';

import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => {
  const settings = useSelector(state => state.settingsReducer);
  const [isLoaded, setIsLoaded] = useState(false);

  const setStylesheetMode = mode => {
    setIsLoaded(false);

    toggleStylesheet({ "isDark": settings.isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [settings.isDark]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes />
    </Router>
  );
};

export default App;