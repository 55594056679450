export const handleFetch = async (url, options) => {

	const header = {
		'Content-Type': 'application/json; charset=utf-8'
	};

	let fetchOptions = Object.assign(
		{},
		{
			headers: header
		},
		options
	);

	let callResponse = {};

	try {
		callResponse = await fetch(url, fetchOptions);

		let payload = await callResponse.json();
		let myReturnObject = {
			data: payload,
			status: callResponse.status
		}


		return myReturnObject;

	} catch (e) {

		let myReturnObject = {
			data: 'We are having some trouble on our end.  Please try again later!',
			status: 500
		}

		return myReturnObject;
	};

}