import { NEW_PROPOSAL as ActionTypes } from '../actionTypes';



const initialState = {
    proposal: {
        proposalId: '',
        batchId: 0,
        statusId: 1,
        statusDisplay: 'Sent',
        agentId: 0,
        agentName: '',
        agentLastName: '',
        agentEmail: '',
        dobNowEmail: '',
        auditDobNowEmail: '',
        customerId: 0,
        customerName: '',
        agreedToTermsAndConditions: '',
        year: '',
        superName: '',
        auditSuperName: '',
        dateCreated: '',
        isPeriodic: false
    },
    proposalDetails: {
        proposalDetailId: 0,
        proposalId: '',
        batchId: 0,
        statusId: 0,
        statusDisplay: '',
        inspectionId: 0,
        inspectionLineId: 0,
        buildingId: 0,
        buildingAddress: '',
        corpName: '',
        auditCorpName: '',
        elevatorCompanyId: 0,
        elevatorCompany: '',
        auditElevatorCompany: '',
        trackAoc: false,
        auditTrackAoc: '',
        qce: false,
        auditQce: false,
        superName: '',
        auditSuperName: '',
        superPhone: '',
        auditSuperPhone: '',
        device: '',
        year: '',
        agentId: 0,
        agentEmail: '',
        dateCreated: '',
        isPeriodic: false,
        agreementThroughYear: 0
    },
    lines: []
};

export const newProposalReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_NEW_PROPOSAL_DETAIL:

            return {
                ...state,
                proposalDetails: action.payload
            };
        case ActionTypes.SET_NEW_PROPOSAL:

            return {
                ...state,
                proposal: action.payload.proposal,
                lines: action.payload.lines
            };


        case ActionTypes.SET_NEW_LINES:
            // used for approve all or reject all
            return { ...state, lines: action.payload };


        case ActionTypes.UPDATE_NEW_LINE:

            let newArray = [...state.lines];

            let index = state.lines.findIndex(c => c.proposalDetailId == action.payload.proposalDetailId);
            newArray[index] = action.payload;

            return { ...state, lines: newArray };

        case ActionTypes.UPDATE_NEW_PROPOSAL:
            // used to update the status of the prop from sent to viewed and from viewed to submitted
            return {
                ...state,
                proposal: action.payload
            };


        default:

            return state;
    };
}