import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Alert, Card, CardBody } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/es/Badge';
import Scrollbar from 'react-scrollbars-custom';

// components
import Logo from "../common/Logo";
import DeviceModal from './components/_DeviceModal';

// services
import * as proposalApi from '../services/proposalApi';
import * as proposalActions from '../redux/actions/proposalActions';

const Proposal = () => {
	const { proposal, lines } = useSelector(state => state.proposalReducer);

	let { id } = useParams();
	let history = useHistory();
	let dispatch = useDispatch();
	const [logo, setLogo] = useState(require("../assets/img/sierra.png"));

	const [showLoader, setShowLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [captchaValue, setCaptchaValue] = useState(false);
	const [errorMessage, setErrorMessage] = useState('error message section');
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [auditDobNowEmail, setAuditDobNowEmail] = useState('');
	const [agentSignature, setAgentSignature] = useState('');
	const [agreedToExpediteFee, setAgreedToExpediteFee] = useState(null);

	useEffect(() => {
		loadProposal();
	}, []);

	const loadProposal = async () => {
		setShowLoader(true);

		if (!id) {
			// we don't have a
			history.push('/notFound');
		}
		let manifest = await proposalApi.getManifest(id);

		if (manifest.status !== 200) {
			history.push('/notFound');
		} else {
			setShowLoader(false);
			let tempExpediting = null;
			if (manifest.data.proposal.agreedToExpediteFee === 1) {
				tempExpediting = true;
			}

			if (manifest.data.proposal.agreedToExpediteFee === 0) {
				tempExpediting = false;
			}


			setAuditDobNowEmail(manifest.data.proposal.auditDobNowEmail);
			setAgentSignature(manifest.data.proposal.agentSignature);
			setAgreedToExpediteFee(tempExpediting);
			setCanEdit(manifest.data.proposal.canEdit);
			dispatch(proposalActions.setProposal(manifest.data));
		}
	}

	// const onDismiss = () => {
	// 	setVisible(false);
	// }

	const captchaOptions = {
		siteKey: "6LeX6-AZAAAAAL6CT39-JX-G0CivQeDwXZDyqX1A",
		captchaRef: useRef("test"),
	};

	const handleCaptcha = (captchaVal) => {
		setCaptchaValue(captchaVal ? true : false);
	};

	const validateForm = () => {

		// make sure the status of the lines has something checked
		if (lines.some(c => c.statusId == 0)) {
			setErrorMessage('Please Approve or Decline each of the buildings.');
			setShowErrorMessage(true);
			return false;
		}

		if (!agentSignature) {
			setErrorMessage('You must sign the proposal.');
			setShowErrorMessage(true);
			return false;
		}

		if (agreedToExpediteFee !== true && agreedToExpediteFee !== false) {
			setErrorMessage('Please select an option for the Expedite Filing Fee.');
			setShowErrorMessage(true);
			return false;
		}

		//validate captcha
		if (!captchaValue) {
			setErrorMessage('Please verify that you are not a robot');
			setShowErrorMessage(true);
			return false;
		};

		setErrorMessage('');
		setShowErrorMessage(false);
		return true;
	};

	const handleSubmit = async () => {
		setShowErrorMessage(validateForm());
		if (validateForm()) {

			let completeProposal = { ...proposal };

			completeProposal.auditDobNowEmail = auditDobNowEmail;
			completeProposal.agentSignature = agentSignature;

			completeProposal.agreedToExpediteFee = agreedToExpediteFee === true ? 1 : 0;
			completeProposal.statusId = 3; // force a submit status change

			let completedLines = [...lines];
			// gather the proposal
			let proposalToSubmit = {
				proposal: completeProposal,
				lines: completedLines
			};

			await proposalApi.submit(proposalToSubmit);
			history.push('/Complete');
		}
	};

	const launchEditModal = (rowToUpdate) => {
		dispatch(proposalActions.setProposalDetail(rowToUpdate));
		setShowModal(true);
	}

	const approveLine = (rowToUpdate) => {
		rowToUpdate.statusId = 1;
		dispatch(proposalActions.updateLine(rowToUpdate));
	}
	const declineLine = (rowToUpdate) => {
		rowToUpdate.statusId = 2;
		dispatch(proposalActions.updateLine(rowToUpdate));
	}

	const updateAll = (status) => {
		let test = lines.map(item => {

			return { ...item, statusId: status };
		});

		dispatch(proposalActions.updateAllLines(test));
	}

	const actionFormatter = (cell, item) => {

		// decide based on line status
		let approvedColor = 'secondary';
		let declinedColor = 'secondary';
		let approveOutline = true;
		let declineOutline = true;

		switch (item.statusId) {
			case 1:
				approvedColor = 'success';
				approveOutline = false;
				break;
			case 2:
				declinedColor = 'danger';
				declineOutline = false;
				break;
			default:
				//approvedColor = 'secondary';
				//declinedColor = 'secondary';
				break;
		};

		return (
			<>
				<Button size="sm" outline={true} className="fs--1 mr-2" color="info" disabled={!canEdit} onClick={() => launchEditModal(item)}>
					Edit
				</Button>
				<Button size="sm" outline={approveOutline} className="fs--1 mr-2" color={approvedColor} disabled={!canEdit} onClick={() => approveLine(item)}>
					<FontAwesomeIcon icon="check" className="fs--4" />
				</Button>
				<Button size="sm" outline={declineOutline} className="fs--1" color={declinedColor} disabled={!canEdit} onClick={() => declineLine(item)}>
					<FontAwesomeIcon icon="ban" className="fs--4" />
				</Button>
			</>
		);
	};

	const corpNameFormat = (cell, item) => {

		let corpName = item.corpName;
		let auditCorpName = item.auditCorpName;

		if (corpName !== auditCorpName) {
			return (
				<>
					<Alert color={'warning'} >{item.auditCorpName}</Alert>
				</>
			);
		} else {
			return item.auditCorpName
		}
	};

	const elevatorFormat = (cell, item) => {

		let elevatorCompany = item.elevatorCompany;
		let auditElevatorCompany = item.auditElevatorCompany;

		if (elevatorCompany !== auditElevatorCompany) {
			return (
				<>
					<Alert color={'warning'} >{item.auditElevatorCompany}</Alert>
				</>
			);
		} else {
			return item.auditElevatorCompany
		}
	};

	const superFormat = (cell, item) => {

		let superName = item.superName;
		let auditSuperName = item.auditSuperName;

		if (superName !== auditSuperName) {
			return (
				<>
					<Alert color={'warning'} >{item.auditSuperName}</Alert>
				</>
			);
		} else {
			return item.auditSuperName
		}
	};

	const superPhoneFormat = (cell, item) => {

		let superPhone = item.superPhone;
		let auditSuperPhone = item.auditSuperPhone;

		if (superPhone !== auditSuperPhone) {
			return (
				<>
					<Alert color={'warning'} >{item.auditSuperPhone}</Alert>
				</>
			);
		} else {
			return item.auditSuperPhone
		}
	};


	const showCheckFormatter = ((cell, item) => {

		// TODO: see if we can change the background when changed
		let trackAoc = item.trackAoc;
		let auditTrackAoc = item.auditTrackAoc;
		if (trackAoc !== auditTrackAoc) {
			if (cell === 1) {
				return <Alert color={'warning'} ><Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
					<FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge></Alert>
			} else {
				return <Alert color={'warning'} ><Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
					<FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge></Alert >
			}
		} else {
			if (cell === 1) {
				return <Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
					<FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge>
			} else {
				return <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
					<FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge>
			}
		}



	});

	const cancelModal = () => {
		setShowModal(false);
	}

	const buttonFormatter = (column, colIndex, { sortElement, filterElement }) => {
		return (
			<>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'success'} disabled={!canEdit} onClick={() => updateAll(1)}>
					Approve
					<FontAwesomeIcon icon="check" className="ml-1 fs--4" />
				</Button>
				<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'danger'} disabled={!canEdit} onClick={() => updateAll(2)}>
					Decline
					<FontAwesomeIcon icon="ban" className="ml-1 fs--4" />
				</Button>
			</>
		);
	}

	const columns = [
		{
			dataField: 'buildingAddress',
			text: 'Building Address',
			classes: 'py-2 align-middle',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			},
		},
		{
			dataField: 'auditCorpName',
			text: 'Corp Building Name',
			classes: 'py-2 align-middle',
			sort: true,
			formatter: corpNameFormat,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: "left" };
			}
		},
		{
			dataField: 'device',
			text: 'Device',
			classes: 'py-2 align-middle',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "10%", textAlign: "left" };
			}
		},
		{
			dataField: 'auditTrackAoc',
			text: 'AOC Tracking',
			classes: 'py-2 align-middle text-center',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "10%", textAlign: "left" };
			},
			formatter: showCheckFormatter
		},
		{
			dataField: 'auditElevatorCompany',
			text: 'Elevator Company',
			classes: 'py-2 align-middle text-left',
			sort: true,
			formatter: elevatorFormat,
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: 'left' };
			}
		},
		{
			dataField: 'auditSuperName',
			text: 'Super Name',
			classes: 'py-2 align-middle text-left',
			sort: true,
			formatter: superFormat,
			headerStyle: (colum, colIndex) => {
				return { width: "10%", textAlign: 'left' };
			}
		},
		{
			dataField: 'auditSuperPhone',
			text: 'Super Phone',
			classes: 'py-2 align-middle text-left',
			sort: true,
			formatter: superPhoneFormat,
			headerStyle: (colum, colIndex) => {
				return { width: "10%", textAlign: 'left' };
			}
		},
		{
			dataField: 'statusId',
			classes: 'align-middle text-right',
			headerClasses: 'border-0',
			text: '',
			headerFormatter: buttonFormatter,
			formatter: (cell, item) => actionFormatter(cell, item),
			headerStyle: (colum, colIndex) => {
				return { width: "15%", textAlign: 'right' };
			}
		}
	];

	return (
		<>

			{showModal === true && <DeviceModal
				showModal={showModal}
				//selectedItem={selectedItem}
				//updateItem={updateUserLists}
				cancelModal={cancelModal}
			/>}

			{showLoader === true ? (
				<>
					<Row noGutters>
						<Col xl={12}>
							<Card className="mb-3">
								<CardBody>
									<Logo location={"auth"} logo={logo} />
									<Row className="m-5">
										<Col xs="12" style={{ alignSelf: 'center', textAlign: 'center' }}>
											<h3>Preparing your proposal.  One moment please......</h3>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>


				</>

			) : (<>


				<Row noGutters>
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Logo location={"auth"} logo={logo} />
								{proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}
								<Row className="m-2">
									<Col>
										<h5 className="text-center">2021 CATEGORY ONE PERIODIC INSPECTION/CATEGORY FIVE YEAR FULL LOAD SAFETY TESTS WITNESSING/AOC TRACKING</h5>
									</Col>
								</Row>
								<Row className="m-2">
									<Col>
										<p className="text-left">
											Sierra Consulting Group, Inc. is pleased to provide the below list of buildings that are on file with our company and will automatically renew. Please review the list and edit as necessary with the approve, decline or edit functions. To add AOC tracking or edit a field, click edit and check approve (if not already checked) and/or make changes as needed. We will follow up on AOC's for six months after inspection date and will not be responsible for late filing or penalties incurred as a result of late filing. To ensure timely scheduling and compliance, please make sure you have approved and returned this proposal as well as your testing proposal submitted by your maintenance elevator contractor. This will enable us to schedule accordingly and within the NYCDOB rules. The NYCDOB regulations state that there must be a six (6) month interval between Category One (1) tests. Sierra Consulting Group, Inc. is not responsible for notifying building/super/resident manager of scheduled tests date(s).
										</p>
									</Col>
								</Row>
								{showErrorMessage == true && <Alert color="danger">{errorMessage}</Alert>}


								<Row>
									<Col xs="12" style={{ alignSelf: 'center' }}>
										<Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>

											<FormGroup>

												<Label>DOBNOW Email - <b>PLEASE CONFIRM DOBNOW EMAIL BELOW AND EDIT/PROVIDE ACCORDINGLY</b></Label>
												<Input
													placeholder="DOBNOW Email"
													disabled={!canEdit}
													value={auditDobNowEmail ? auditDobNowEmail : ''}
													onChange={(e) => setAuditDobNowEmail(e.target.value)}
												/>
											</FormGroup>
										</Alert>
									</Col>
								</Row>
								<Row>
									<Col xl={4}>
										<div className="text-center">
											<Label>Customer</Label>
											<h5>{(proposal && proposal.customerName) ? proposal.customerName : ''}</h5>
										</div>
									</Col>
									<Col xl={4}>
										<div className="text-center">
											<Label>Agent</Label>
											<h5>{(proposal && proposal.agentName) ? proposal.agentName : ''}</h5>
										</div>
									</Col>
									<Col xl={4}>
										<div className="text-center">
											<Label>Email</Label>
											<h5>{(proposal && proposal.agentEmail) ? proposal.agentEmail : ''}</h5>
										</div>
									</Col>
								</Row>
							</CardBody>

						</Card>
					</Col>
				</Row>

				<Row noGutters>
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Row className="m-3">
									<Col xs="4">
										<h5>Witness Fees</h5>
										<div className="text-left mb-3 mt-2">
											<Label>Cat 1 Fee Per Device</Label>
											<h5>${(proposal && proposal.cat1Fee) ? proposal.cat1Fee : ''}</h5>
										</div>
										<div className="text-left">
											<Label>Cat 5 Fee Per Device*</Label>
											<h5>${(proposal && proposal.witnessFee) ? proposal.witnessFee : ''}</h5>
										</div>
									</Col>
									<Col xs="4">
										<h5>Filing Fees</h5>
										<div className="text-left mb-3 mt-2">
											<Label>Cat 1 Filing Fee Per Device</Label>
											<h5>$30</h5>
										</div>
										<div className="text-left">
											<Label>Cat 5 Filing Fee Per Device</Label>
											<h5>$40</h5>
										</div>
									</Col>
									<Col xs="4">
										<h5>Additional Services</h5>
										<div className="text-left mb-3 mt-2">
											<Label>AOC Tracking Fee Per Device</Label>
											<h5>${(proposal && proposal.aocFee) ? proposal.aocFee : ''}</h5>
										</div>
										<div className="text-left mb-3">
											<Label>Expediting Fee</Label>
											<h5>${(proposal && proposal.expediteFee) ? proposal.expediteFee : ''} per filing fee paid</h5>
										</div>
									</Col>
									<Col xs="12">
										<Alert color="secondary">* Cat 5 fee listed is when performed simultaneously with Cat 1</Alert>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>


				<Row noGutters>
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Row className="align-items-center">
									<Col className="col-6 text-left  py-2">
										<h5>Buildings List</h5>
										<h6>Please review the list below, edit as needed and approve or decline.</h6>
									</Col>
									<Col className="col-6 text-right py-2">
										{/* <Label className="mr-2">Approve All Devices</Label>
									<Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color="success" onClick={() => updateAll(1)}>
										<FontAwesomeIcon icon='check' className="ml-1 fs--4" style={{ width: 25 }} />
									</Button> */}

									</Col>
								</Row>
								<div className="table-responsive">
									{(lines.length !== 0) &&
										<BootstrapTable
											bootstrap4
											keyField="proposalDetailsId"
											data={lines}
											columns={columns}
											bordered={false}
											classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
											rowClasses="btn-reveal-trigger"
											headerClasses="bg-200 text-900"
										/>
									}

								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>


				<Row noGutters>
					<Col xl={12}>
						<Card className="mb-3">
							<CardBody>
								<Row>
									<Col xs="12" style={{ alignSelf: 'center' }}>
										<Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>



											<FormGroup >
												<Label>Terms and Conditions</Label>
												<Scrollbar style={{ height: 150, backgroundColor: 'white', margin: 2 }}>
													<div className="p-2">


														<p className="fs--2" style={{ fontSize: 10 }}>
															Sierra Consulting Group, Inc. shall submit an invoice for payment upon completion of services, and is due within 30 days. Client agrees to pay, in addition to the price stated herein, the amount of any applicable taxes by any law enacted before or after the date of this agreement or imposed by any existing or future law. Should you require Sierra Consulting Group, Inc. to register with a third-party compliance and/or insurance vendor, additional fees may apply.</p>

														<p className="fs--2" style={{ fontSize: 10 }}>Sierra Consulting Group, Inc., while conducting the witnessing of the inspection/test, will not be held responsible and will remain harmless by you (Owner) to damages or incidences to the elevator equipment and/or surround structures resulting from the inspection/test. It is understood that Sierra Consulting Group, Inc. will make recommendations and suggestions based on professional expertise. Sierra Consulting Group, Inc. has made no promises, expressed or implied to the mechanical or electrical integrity of the Vertical Transportation System. Sierra Consulting Group, Inc. does not assume any responsibility for the related equipment or riding passengers. We only witness the testing procedures conducted by the elevator contractor and conduct a visual examination of the equipment with recommendations to bring the systems into compliance with all applicable codes. The client also agrees to shut an elevator down if a dangerous condition is uncovered until proper repairs can be made. Notice of inspection cancellation shall be no less than twenty-four (24) hours. In the event of a cancellation Sierra Consulting Group, Inc. will invoice up to two (2) hours at our hourly rate.
														</p>

														<p className="fs--2" style={{ fontSize: 10 }}>Upon execution of this agreement, Building Owner/Management Company and the Elevator Contractor associated with the premises hereby agrees to indemnify, hold harmless and name as additionally insured Sierra Consulting Group, Inc., its directors, employees and representatives against all liability claims and demands on account of injury to persons including death resulting therefrom and damage to property to the extent arising out of the negligent performance of this contract by the Contractor, employees and agents of the Contractor and Contractor's property with limits of $1 million in general liability, $2 million aggregate and $5 million umbrella coverage. Elevator contractor to provide Sierra as additionally insured on a CG 2010 & CG 2037 forms naming Sierra Consulting Group, Inc., Owner and Property Management. The Contractor shall, at their own expense, defend any and all actions at law brought against Sierra Consulting Group, the Owner and Property Management Company, pay all attorney fees and all other expenses and promptly discharge any judgement arising therefrom. These conditions also shall apply to any of the Contractor's subcontracted operations.
														</p>

														<p className="fs--2" style={{ fontSize: 10 }}>All required DOBNOW signatures must be completed upon immediate request by elevator contractor or Sierra Consulting Group, Inc. Sierra Consulting Group, Inc. will not be liable for late fees or penalties incurred as a result of a late filing. In addition, client agrees to have a signed agreement with their licensed elevator maintenance contractor for the performance of all required category tests.
														</p>

														<p className="fs--2" style={{ fontSize: 10 }}>This proposal is good for a period of up to 90 days from the date of issuance. Proposals not received within the 90 days are subject to change or renegotiation. Sierra Consulting Group, Inc. and the client have the right to cancel this agreement with each other if either breaches any terms, with 30 days written notice. This agreement shall remain in effect from the date signed for a three (3) year period with an annual escalation of three percent (3%) and shall continue thereafter unless written notice is given by either party. Cancellations shall be subject to the immediate payment of all outstanding invoices and fees. Sierra Consulting Group, Inc. has the right to discontinue our work at any time until payments have been made as agreed and we have assurance, satisfactory to us, that subsequent payments will be made when due. Sierra Consulting Group, Inc may assign or transfer its obligations, responsibilities, and rights under this agreement without written notice to Owner/Agent. In the event that the inspection/test exceeds the four (4) hour allotted time per device and/or requires an inspection time/day different from our normal working hours (as noted below), overtime rates may apply.
														</p>

														<p className="fs--2" style={{ fontSize: 10 }}>The following work is not included in our renewal agreement and/or will be billed at our hourly billing rates. Additional proposals will be provided at request of owner/management: testing of firefighters emergency operation, if preformed after hours, testing of door lock monitoring, retesting of elevators due to critical deficiency or equipment failure, and re-inspection of elevators for confirmation on completed affirmation of corrections. Additional fee rates are as follows: <b>straight time (M-F, 8:00am-4:00pm) $250.00 per hour; overtime $375.00 per hour.</b>
														</p>

													</div>
												</Scrollbar>
												{/* <br />
											<CustomInput
												type="switch"
												id="termsAndConditions"
												label="I agree to the terms and conditions in the link below"
												checked={agreeToTermsAndConditions}
												onChange={({ target }) => setAgreeToTermsAndConditions(target.checked)}
											/> */}
											</FormGroup>
										</Alert>
									</Col>
								</Row>

								<Row>
									<Col xs="6" style={{ alignSelf: 'center' }}>
										<Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>
											<FormGroup >
												<Label>Signature</Label>
												<Input
													placeholder="Agent Signature"
													disabled={!canEdit}
													value={agentSignature ? agentSignature : ''}
													onChange={(e) => setAgentSignature(e.target.value)}
												/>
												<small>By Signing above, I agree to Sierra Consulting Group, Inc.'s Terms & Conditions</small>
											</FormGroup>
										</Alert>
									</Col>
									<Col xs="6" style={{ alignSelf: 'center' }}>
										<Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center', height: 135 }}>
											<FormGroup >
												<Label>Expedite Filing Fee Agreement</Label>
												<Row className="mb-3">
													<Col xs="12" md="2">
														<Row xs="12" md="auto">
															<Col xs="6" md="auto">
																Yes
															</Col>
															<Col xs="6" md="auto">
																<Input
																	type="radio"
																	name="expediteFee"
																	placeholder="Yes"

																	disabled={!canEdit}
																	checked={agreedToExpediteFee === true ? true : false}
																	onChange={({ target }) => setAgreedToExpediteFee(true)}
																/>
															</Col>
														</Row>
													</Col>

													<Col xs="12" md="2">
														<Row xs="12" md="auto">
															<Col xs="6" md="auto">
																No
															</Col>
															<Col xs="6" md="auto">
																<Input
																	type="radio"
																	name="expediteFee"
																	placeholder="No"

																	disabled={!canEdit}
																	checked={agreedToExpediteFee === false ? true : false}
																	onChange={({ target }) => setAgreedToExpediteFee(false)}
																/>
															</Col>
														</Row>
													</Col>

												</Row>
												<small>*Required</small>
											</FormGroup>
										</Alert>
									</Col>
								</Row>




								{canEdit === true && <>
									<Row>
										<Col xs="12" style={{ alignSelf: 'center' }}>
											<Row className="mt-3 justify-content-center mb-3">
												<Col xs="auto">
													<ReCAPTCHA
														ref={captchaOptions.captchaRef}
														sitekey={captchaOptions.siteKey}
														onChange={handleCaptcha}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col xs="12" style={{ alignSelf: 'center' }}>
											{showErrorMessage && <Alert color="danger">{errorMessage}</Alert>}
											<Row className="mt-3 justify-content-center mb-3">
												<Col xs="auto">
													<Button
														type="submit" className="btn-success btn-lg" onClick={handleSubmit}>Submit</Button>
												</Col>
											</Row>

										</Col>
									</Row>

								</>}

								{proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}


							</CardBody>
						</Card>
					</Col>
				</Row>

			</>)}

		</>
	);
};

export default Proposal;
