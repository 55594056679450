import { combineReducers } from 'redux';
import { proposalReducer } from './reducers/proposalReducer';
import { newProposalReducer } from './reducers/newProposalReducer';
import { settingsReducer } from './reducers/settingsReducer';


export const appReducer = combineReducers(
	{
		proposalReducer,
		newProposalReducer,
		settingsReducer
	});