import { NEW_PROPOSAL as ActionTypes } from '../actionTypes';


export function setNewProposal(payload) {

    return async (dispatch) => {


        // make api call
        dispatch(
            {
                type: ActionTypes.SET_NEW_PROPOSAL,
                payload: payload
            }
        )
    }
}



export function setNewProposalDetail(payload) {
    // used to set edit item for our detail line modal
    return async (dispatch) => {

        // make api call
        dispatch(
            {
                type: ActionTypes.SET_NEW_PROPOSAL_DETAIL,
                payload: payload
            }
        )
    }
}

export function updateNewProposalStatus(payload) {
    // used to update the status from send to viewed and from viewed to submitted
    return async (dispatch) => {


        dispatch(
            {
                type: ActionTypes.UPDATE_NEW_PROPOSAL,
                payload: payload
            }
        )
    }
}


export function updateNewLine(payload) {
    return async (dispatch) => {
        // just send the row that we are updating



        dispatch(
            {
                type: ActionTypes.UPDATE_NEW_LINE,
                payload: payload
            }
        )
    }
}


export function updateNewAllLines(payload) {
    return async (dispatch) => {
        // just send the row that we are updating

        dispatch(
            {
                type: ActionTypes.SET_NEW_LINES,
                payload: payload
            }
        )
    }
}