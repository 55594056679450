import { Container } from 'reactstrap';
import React from 'react';

//import Background from './Background';

const Section = (props) => {


	return (
		<section className="p-0">
			<Container fluid={true}>{props.children}</Container>
		</section>
	);
};


export default Section;