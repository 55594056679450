export const PROPOSAL = {
	SET_PROPOSAL: 'SET_PROPOSAL',
	SET_PROPOSAL_DETAIL: 'SET_PROPOSAL_DETAIL',
	UPDATE_PROPOSAL: 'UPDATE_PROPOSAL',

	UPDATE_LINE: 'UPDATE_LINE',
	SET_LINES: 'SET_LINES'
};

export const NEW_PROPOSAL = {
	SET_NEW_PROPOSAL: 'SET_NEW_PROPOSAL',
	SET_NEW_PROPOSAL_DETAIL: 'SET_NEW_PROPOSAL_DETAIL',
	UPDATE_NEW_PROPOSAL: 'UPDATE_NEW_PROPOSAL',
	UPDATE_NEW_LINE: 'UPDATE_NEW_LINE',
	SET_NEW_LINES: 'SET_NEW_LINES'
};

export const SETTINGS = {
	GET_SETTINGS: 'GET_SETTINGS',
	SET_SETTINGS: 'SET_SETTINGS',

	SET_SHOW_BURGER_MENU: 'SET_SHOW_BURGER_MENU',
	SET_IS_SIDE_PANEL_OPEN: 'SET_IS_SIDE_PANEL_OPEN',
	SET_IS_DARK: 'SET_IS_DARK',
	SET_IS_TOP_NAV: 'SET_IS_TOP_NAV',
	SET_NAV_BAR_STYLE: 'SET_NAV_BAR_STYLE',
	SET_IS_FLUID: 'SET_IS_FLUID',
	SET_IS_NAVBAR_VERTICAL_COLLAPSED: 'SET_IS_NAVBAR_VERTICAL_COLLAPSED'
};