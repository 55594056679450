import { PROPOSAL as ActionTypes } from '../actionTypes';


export function setProposal(payload) {

	return async (dispatch) => {

		// make api call
		dispatch(
			{
				type: ActionTypes.SET_PROPOSAL,
				payload: payload
			}
		)
	}
}


export function setProposalDetail(payload) {
	// used to set edit item for our detail line modal
	return async (dispatch) => {

		// make api call
		dispatch(
			{
				type: ActionTypes.SET_PROPOSAL_DETAIL,
				payload: payload
			}
		)
	}
}

export function updateProposalStatus(payload) {
	// used to update the status from send to viewed and from viewed to submitted
	return async (dispatch) => {


		dispatch(
			{
				type: ActionTypes.UPDATE_PROPOSAL,
				payload: payload
			}
		)
	}
}


export function updateLine(payload) {
	return async (dispatch) => {
		// just send the row that we are updating


		debugger;
		dispatch(
			{
				type: ActionTypes.UPDATE_LINE,
				payload: payload
			}
		)
	}
}


export function updateAllLines(payload) {
	return async (dispatch) => {
		// just send the row that we are updating

		dispatch(
			{
				type: ActionTypes.SET_LINES,
				payload: payload
			}
		)
	}
}