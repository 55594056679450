import React, { useEffect } from 'react';

import classNames from 'classnames';

const Logo = ({ location, width, logo, ...rest }) => {
	useEffect(() => {


	}, []);
	return (

		<div
			className={classNames(
				'd-flex',
				{
					'align-items-center py-3': location === 'navbar-vertical',
					'align-items-center': location === 'navbar-top',
					'flex-center font-weight-extra-bold fs-5 mb-4': location === 'auth'
				}
			)}
		>
			<img className="mr-2 img-fluid" src={logo} />
			{/* <span className="text-sans-serif">{title}</span> */}
		</div>

	);
};


export default Logo;
