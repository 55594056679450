import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Alert, Card, CardBody } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/es/Badge';
import Scrollbar from 'react-scrollbars-custom';

// components
import Logo from "../common/Logo";
import DeviceModal from './components/_DeviceModal';

// services
//import * as proposalApi from '../services/proposalApi';
import * as newProposalApi from '../services/newProposalApi';
import * as newProposalActions from '../redux/actions/newProposalActions';
import moment from "moment";

const NewProposal = () => {

    const { proposal, lines } = useSelector(state => state.newProposalReducer);

    let { id } = useParams();
    let history = useHistory();
    let dispatch = useDispatch();

    const [logo, setLogo] = useState(require("../assets/img/sierra.png"));
    const [showLoader, setShowLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);
    const [errorMessage, setErrorMessage] = useState('error message section');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [auditDobNowEmail, setAuditDobNowEmail] = useState('');
    const [agentSignature, setAgentSignature] = useState('');
    const [agreedToExpeditingFee, setAgreedToExpediteFee] = useState(null);

    useEffect(() => {

        loadProposal();
    }, []);



    const loadProposal = async () => {

        setShowLoader(true);

        if (!id) {
            // we don't have a proposal id

            history.push('/notFound');
        }
        let manifest = await newProposalApi.getNewManifest(id);

        if (manifest.status !== 200) {

            history.push('/notFound');
        } else {
            setShowLoader(false);
            let tempExpediting = null;
            if (manifest.data.proposal.agreedToExpeditingFee === true) {
                tempExpediting = true;
            }

            if (manifest.data.proposal.agreedToExpeditingFee === false) {
                tempExpediting = false;
            }

            setAuditDobNowEmail(manifest.data.proposal.auditDobNowEmail);
            setAgentSignature(manifest.data.proposal.agentSignature);
            setAgreedToExpediteFee(tempExpediting);
            setCanEdit(manifest.data.proposal.canEdit);
            dispatch(newProposalActions.setNewProposal(manifest.data));
        }
    }

    // const onDismiss = () => {
    // 	setVisible(false);
    // }

    const captchaOptions = {
        siteKey: "6LeX6-AZAAAAAL6CT39-JX-G0CivQeDwXZDyqX1A",
        captchaRef: useRef("test"),
    };

    const handleCaptcha = (captchaVal) => {
        setCaptchaValue(captchaVal ? true : false);
    };

    const validateForm = () => {

        // make sure the status of the lines has something checked
        if (lines.some(c => c.statusId == 0)) {
            setErrorMessage('Please Approve or Decline each of the buildings.');
            setShowErrorMessage(true);
            return false;
        }

        if (!agentSignature) {
            setErrorMessage('You must sign the proposal.');
            setShowErrorMessage(true);
            return false;
        }

        if (agreedToExpeditingFee !== true && agreedToExpeditingFee !== false) {
            setErrorMessage('Please select an option for the Expedite Filing Fee.');
            setShowErrorMessage(true);
            return false;
        }

        //validate captcha
        if (!captchaValue) {
            setErrorMessage('Please verify that you are not a robot');
            setShowErrorMessage(true);
            return false;
        };

        setErrorMessage('');
        setShowErrorMessage(false);
        return true;
    };

    const handleSubmit = async () => {

        setShowErrorMessage(validateForm());
        if (validateForm()) {

            let completeProposal = { ...proposal };

            completeProposal.auditDobNowEmail = auditDobNowEmail;
            completeProposal.agentSignature = agentSignature;

            completeProposal.agreedToExpeditingFee = agreedToExpeditingFee;
            completeProposal.statusId = 3; // force a submit status change

            let completedLines = [...lines];
            console.log(completedLines);

            // gather the proposal
            let proposalToSubmit = {
                proposal: completeProposal,
                lines: completedLines
            };

            await newProposalApi.submit(proposalToSubmit);
            history.push('/Complete');
        }
    };

    const launchEditModal = (rowToUpdate) => {
        dispatch(newProposalActions.setNewProposalDetail(rowToUpdate));
        setShowModal(true);
    }

    const approveLine = (rowToUpdate) => {
        rowToUpdate.statusId = 1;
        dispatch(newProposalActions.updateNewLine(rowToUpdate));
    }
    const declineLine = (rowToUpdate) => {
        rowToUpdate.statusId = 2;
        dispatch(newProposalActions.updateNewLine(rowToUpdate));
    }

    const updateAll = (status) => {
        let test = lines.map(item => {
            if (item.agreementThroughYear <= moment(new Date).year()) {

                return { ...item, statusId: status };
            } else {
                return { ...item, statusId: 1 };
            }
        });

        dispatch(newProposalActions.updateNewAllLines(test));
    }

    const actionFormatter = (cell, item) => {

        // decide based on line status
        let approvedColor = 'secondary';
        let declinedColor = 'secondary';
        let approveOutline = true;
        let declineOutline = true;


        let isDisabled = false;
        if (item.agreementThroughYear > moment(new Date).year()) {

            isDisabled = true;
        }

        switch (item.statusId) {
            case 1:
                approvedColor = 'success';
                approveOutline = false;
                break;
            case 2:
                declinedColor = 'danger';
                declineOutline = false;
                break;
        };



        return (
            <>
                <Button size="sm" outline={true} className="fs--1 mr-2" color="info" disabled={!canEdit} onClick={() => launchEditModal(item)}>
                    Edit
                </Button>
                <Button size="sm" outline={approveOutline} className="fs--1 mr-2" color={approvedColor} disabled={!canEdit} onClick={() => approveLine(item)}>
                    <FontAwesomeIcon icon="check" className="fs--4" />
                </Button>
                <Button size="sm" outline={declineOutline} className="fs--1" color={declinedColor} disabled={isDisabled} onClick={() => declineLine(item)}>
                    <FontAwesomeIcon icon="ban" className="fs--4" />
                </Button>
            </>
        );
    };

    const corpNameFormat = (cell, item) => {

        let corpName = item.corpName;
        let auditCorpName = item.auditCorpName;

        if (corpName !== auditCorpName) {
            return (
                <>
                    <Alert color={'warning'} >{item.auditCorpName}</Alert>
                </>
            );
        } else {
            return item.auditCorpName
        }
    };

    const elevatorFormat = (cell, item) => {

        let elevatorCompany = item.elevatorCompany;
        let auditElevatorCompany = item.auditElevatorCompany;

        if (elevatorCompany !== auditElevatorCompany) {
            return (
                <>
                    <Alert color={'warning'} >{item.auditElevatorCompany}</Alert>
                </>
            );
        } else {
            return item.auditElevatorCompany
        }
    };

    const superFormat = (cell, item) => {

        let superName = item.superName;
        let auditSuperName = item.auditSuperName;

        if (superName !== auditSuperName) {
            return (
                <>
                    <Alert color={'warning'} >{item.auditSuperName}</Alert>
                </>
            );
        } else {
            return item.auditSuperName
        }
    };

    const superPhoneFormat = (cell, item) => {

        let superPhone = item.superPhone;
        let auditSuperPhone = item.auditSuperPhone;

        if (superPhone !== auditSuperPhone) {
            return (
                <>
                    <Alert color={'warning'} >{item.auditSuperPhone}</Alert>
                </>
            );
        } else {
            return item.auditSuperPhone
        }
    };


    const showCheckFormatter = ((cell, item) => {

        // TODO: see if we can change the background when changed
        let trackAoc = item.trackAoc;
        let auditTrackAoc = item.auditTrackAoc;

        if (trackAoc !== auditTrackAoc) {
            if (cell === true) {
                return <Alert color={'warning'} ><Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge></Alert>
            } else {
                return <Alert color={'warning'} ><Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge></Alert >
            }
        } else {
            if (cell === true) {
                return <Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge>
            } else {
                return <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                    <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge>
            }
        }
    });

    const showQceFormatter = ((cell, item) => {

        // TODO: see if we can change the background when changed
        let qce = item.qce;
        let auditQce = item.auditQce;
        if (item.isPeriodic === false) {
            if (qce !== auditQce) {
                if (cell === true) {
                    return <Alert color={'warning'} ><Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge></Alert>
                } else {
                    return <Alert color={'warning'} ><Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge></Alert >
                }
            } else {
                if (cell === true) {
                    return <Badge color={`soft-success`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='check' className="fs--4" color={'green'} /></Badge>
                } else {
                    return <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1 d-block">
                        <FontAwesomeIcon icon='ban' className="fs--4" color={'red'} /></Badge>
                }
            }
        } else {
            return <Badge color={`soft-secondary`} style={{ width: '50px' }} className="rounded-capsule fs--1 d-block">
                NA</Badge>
        }

    });


    const inspTypeColumn = ((cell, item) => {

        if (item.isPeriodic === true) {
            return <Alert color={'primary'} >
                Periodic
            </Alert>
        } else {
            return <Alert color={'info'} >
                Category</Alert >
        }

    });

    const cancelModal = () => {
        setShowModal(false);
    }

    const buttonFormatter = (column, colIndex, { sortElement, filterElement }) => {

        return (
            <>
                <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'success'} disabled={!canEdit} onClick={() => updateAll(1)}>
                    Approve
                    <FontAwesomeIcon icon="check" className="ml-1 fs--4" />
                </Button>
                <Button size="sm" outline={true} className="pr-3 fs--1 mr-2" color={'danger'} disabled={!canEdit} onClick={() => updateAll(2)}>
                    Decline
                    <FontAwesomeIcon icon="ban" className="ml-1 fs--4" />
                </Button>
            </>
        );
    };

    const yearFormat = (cell, item) => {

        let superPhone = item.superPhone;
        let auditSuperPhone = item.auditSuperPhone;

        if (item.agreementThroughYear <= moment(new Date).year()) {

            return <Alert color={'danger'}>{item.agreementThroughYear}</Alert>;
        } else {
            return <Alert color={'success'}>{item.agreementThroughYear}</Alert>;
        }


        // if (superPhone !== auditSuperPhone) {
        //     return (
        //         <>

        //         </>
        //     );
        // } else {
        //     return item.auditSuperPhone
        // }
    };

    const columns = [
        {
            dataField: 'agreementThroughYear',
            text: 'Agreement Through Year',
            classes: 'py-2 align-middle',
            sort: true,
            formatter: yearFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "5%", textAlign: "left" };
            },
        },
        {
            dataField: 'buildingAddress',
            text: 'Building Address',
            classes: 'py-2 align-middle',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: "left" };
            },
        },
        {
            dataField: 'auditCorpName',
            text: 'Corp Building Name',
            classes: 'py-2 align-middle',
            sort: true,
            formatter: corpNameFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: "left" };
            }
        },
        {
            dataField: 'device',
            text: 'Device',
            classes: 'py-2 align-middle',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            }
        },
        {
            dataField: 'isPeriodic',
            text: 'Inspection Type',
            classes: 'py-2 align-middle',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: inspTypeColumn

        },
        {
            dataField: 'auditTrackAoc',
            text: 'AOC Tracking',
            classes: 'py-2 align-middle text-center',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: showCheckFormatter
        },
        {
            dataField: 'auditQce',
            text: 'QCE',
            classes: 'py-2 align-middle text-center',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: "left" };
            },
            formatter: showQceFormatter
        },
        {
            dataField: 'auditElevatorCompany',
            text: 'Elevator Company',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: elevatorFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: 'left' };
            }
        },
        {
            dataField: 'auditSuperName',
            text: 'Super Name',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: superFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: 'left' };
            }
        },
        {
            dataField: 'auditSuperPhone',
            text: 'Super Phone',
            classes: 'py-2 align-middle text-left',
            sort: true,
            formatter: superPhoneFormat,
            headerStyle: (colum, colIndex) => {
                return { width: "10%", textAlign: 'left' };
            }
        },
        {
            dataField: 'statusId',
            classes: 'align-middle text-right',
            headerClasses: 'border-0',
            text: '',
            headerFormatter: buttonFormatter,
            //formatter: (cell, item) => actionFormatter(cell, item),
            formatter: actionFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: "15%", textAlign: 'right' };
            }
        }
    ];

    return (
        <>

            {showModal === true && <DeviceModal
                showModal={showModal}
                //selectedItem={selectedItem}
                //updateItem={updateUserLists}
                cancelModal={cancelModal}
            />}

            {showLoader === true ? (
                <>
                    <Row noGutters>
                        <Col xl={12}>
                            <Card className="mb-3">
                                <CardBody>
                                    <Logo location={"auth"} logo={logo} />
                                    <Row className="m-5">
                                        <Col xs="12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                            <h3>Preparing your proposal.  One moment please......</h3>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>

            ) : (<>


                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Logo location={"auth"} logo={logo} />
                                {proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}
                                <Row className="m-2">
                                    <Col>
                                        <h5 className="text-center">CATEGORY ONE INSPECTION/CATEGORY FIVE YEAR FULL LOAD SAFETY TESTS WITNESSING/PERIODIC INSPECTION/AOC TRACKING/QUALITY CONTROL EVALUATIONS</h5>
                                    </Col>
                                </Row>
                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            Sierra Consulting Group, Inc. (Sierra) is pleased to provide this extension agreement for your annual elevator inspections. This agreement includes the following services and is subject to terms and conditions:
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>

                                        <ul>
                                            <li>Third Party Witness for Category One (1) Elevator Inspection/Test: NYCDOB regulations state that there must be a six (6) month interval between Category One (1) tests</li>
                                            <li>Third Party Witness for Category Five (5) Full Load Safety Inspection/Test</li>
                                            <li>Third Party Inspection/Test for Periodic Inspections: NYCDOB regulations state that there must be a three (3) month interval between Periodic Inspections and Category One</li>
                                            <li>Additional/Optional Services</li>
                                            <ul>
                                                <li><b>Affirmation of Corrections Tracking (AOC):</b> All inspections that report unsatisfactory results must be corrected and filed with NYC Department of Buildings (NYCDOB) within certain timeframes. AOC’s will be tracked for a period of six (6) months following the initial inspection/witness date and our compliance department will make every effort possible to work with the elevator company and building agent/owner to ensure that corrections are completed and filed within the allotted timeframes mandated by NYCDOB. Sierra will not be responsible for late filings or penalties incurred because of late filings</li>
                                                <li><b>Expediting DOB Filing Fees:</b> NYC DOB filing fees are the responsibility of the Building Owner/Manager. Upon completion and attestation from all related parties, Sierra will process the payment on your behalf (if the elevator is free from any elevator related elevator penalty and/or fine). The DOB Filing fee will be passed through with an additional expediting fee for each filing fee paid.</li>
                                                <li><b>Quality Control Evaluation (QCE):</b> To be performed in conjunction with your category one test, Sierra will provide a written report of your elevator maintenance conditions compared to industry standards, itemization of maintenance deficiencies, current operating performance times, known current and future code-mandated upgrade/alterations, system equipment profile, preliminary violation search on NYC DOB Bis as well as short- and long-term recommendations. Note that if a QCE is requested independent from the category inspection, a separate proposal will be provided with different rates</li>
                                            </ul>
                                        </ul>

                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            Below is a list of buildings/devices/inspection types that are on file with our company. Please review the list and edit as necessary with the approve, decline, or edit functions. You can simply add or remove options with the edit button and make changes as needed including adding or removing additional services, updating elevator maintenance company for building and super information. To edit a field, click edit and check approve (if not already checked) and/or make changes as needed.
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="m-2">
                                    <Col>
                                        <p className="text-left">
                                            To ensure timely scheduling and compliance, please make sure you have approved and returned this proposal as well as your testing proposal submitted by your maintenance elevator contractor. This will enable us to schedule accordingly and within the NYCDOB rules. Sierra is not responsible for notifying the building/super/resident manager of scheduled tests date(s).
                                        </p>
                                    </Col>
                                </Row>


                                {showErrorMessage == true && <Alert color="danger">{errorMessage}</Alert>}


                                <Row>
                                    <Col xs="12" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>

                                            <FormGroup>

                                                <Label>DOBNOW Email - <b>PLEASE CONFIRM DOBNOW EMAIL BELOW AND EDIT/PROVIDE ACCORDINGLY</b></Label>
                                                <Input
                                                    placeholder="DOBNOW Email"
                                                    disabled={!canEdit}
                                                    value={auditDobNowEmail ? auditDobNowEmail : ''}
                                                    onChange={(e) => setAuditDobNowEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Customer</Label>
                                            <h5>{(proposal && proposal.customerName) ? proposal.customerName : ''}</h5>
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Agent</Label>
                                            <h5>{(proposal && proposal.agentName) ? proposal.agentName : ''}</h5>
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                        <div className="text-center">
                                            <Label>Email</Label>
                                            <h5>{(proposal && proposal.agentEmail) ? proposal.agentEmail : ''}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>

                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row className="m-3">
                                    <Col xs="4">
                                        <h5>Inspection Fees</h5>
                                        <div className="text-left mb-3 mt-2">
                                            <Label>Cat 1 Fee Per Device Per Inspection</Label>
                                            <h5>${(proposal && proposal.cat1Fee) ? proposal.cat1Fee : ''}</h5>
                                        </div>
                                        <div className="text-left mb-3 mt-2">
                                            <Label>Periodic Fee Per Device Per Inspection</Label>
                                            <h5>${(proposal && proposal.periodicFee) ? proposal.periodicFee : ''}</h5>
                                        </div>
                                        <div className="text-left">
                                            <Label>Cat 5 Fee Per Device Per Inspection *</Label>
                                            <h5>${(proposal && proposal.cat5Fee) ? proposal.cat5Fee : ''}</h5>
                                        </div>
                                        <Alert color="secondary">* Cat 5 fee listed is when performed simultaneously with Cat 1</Alert>
                                    </Col>
                                    <Col xs="4">
                                        <h5>Filing Fees</h5>
                                        <div className="text-left mb-3 mt-2">
                                            <Label>Cat 1 Filing Fee Per Device Per Inspection</Label>
                                            <h5>$30</h5>
                                        </div>
                                        <div className="text-left mb-3 mt-2">
                                            <Label>Periodic Filing Fee Per Device Per Inspection</Label>
                                            <h5>$30</h5>
                                        </div>
                                        <div className="text-left">
                                            <Label>Cat 5 Filing Fee Per Device Per Inspection</Label>
                                            <h5>$40</h5>
                                        </div>
                                    </Col>
                                    <Col xs="4">
                                        <h5>Additional Services</h5>
                                        <div className="text-left mb-3 mt-2">
                                            <Label>AOC Tracking Fee Per Device Per Inspection</Label>
                                            <h5>${(proposal && proposal.aocFee) ? proposal.aocFee : ''}</h5>
                                        </div>
                                        <div className="text-left mb-3">
                                            <Label>Expediting Fee Per Device Per Inspection</Label>
                                            <h5>${(proposal && proposal.expeditingFee) ? proposal.expeditingFee : ''} per filing fee paid</h5>
                                        </div>
                                        <div className="text-left mb-3">
                                            <Label>QCE Fee Per Device Per QCE Performed *</Label>
                                            <h5>${(proposal && proposal.qceFee) ? proposal.qceFee : ''} </h5>
                                        </div>
                                        <Alert color="secondary">* QCE fee listed is when performed simultaneously with Cat 1</Alert>
                                    </Col>



                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row className="align-items-center">
                                    <Col className="col-6 text-left  py-2">
                                        <h5>Buildings List</h5>

                                    </Col>
                                    <Col className="col-6 text-right py-2">

                                    </Col>
                                </Row>

                                <Alert color='info'><u><b>Attention:</b></u> Please be advised that your proposal will reflect an Agreement Through Year for each building address respectively.
                                    Agreement Through years noted in <Badge color={`soft-danger`} style={{ width: '40px' }} className="rounded-capsule fs--1">RED </Badge> will be expiring or have expired, and rates above will apply on renewal. Buildings listed with Agreement Through Date in
                                    {' '}<Badge color={`soft-success`} style={{ width: '60px' }} className="rounded-capsule fs--1">GREEN</Badge>{' '}
                                    are currently under contract through and including the year noted and will auto renew. You have ability to edit all buildings for additional services or update information as needed.

                                </Alert>
                                <div className="table-responsive">
                                    {(lines.length !== 0) &&
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="proposalDetailId"
                                            data={lines}
                                            columns={columns}
                                            bordered={false}
                                            classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                                            rowClasses="btn-reveal-trigger"
                                            headerClasses="bg-200 text-900"
                                        />
                                    }

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Row noGutters>
                    <Col xl={12}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col xs="12" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>



                                            <FormGroup >
                                                <Label>Terms and Conditions</Label>
                                                <Scrollbar style={{ height: 150, backgroundColor: 'white', margin: 2 }}>
                                                    <div className="p-2">


                                                        <p className="fs--2" style={{ fontSize: 10 }}>
                                                            Sierra Consulting Group, Inc. shall submit an invoice for payment upon completion of services, and is due within 30 days. Client agrees to pay, in addition to the price stated herein, the amount of any applicable taxes by any law enacted before or after the date of this agreement or imposed by any existing or future law. Should you require Sierra Consulting Group, Inc. to register with a third-party compliance and/or insurance vendor, additional fees may apply.</p>

                                                        <p className="fs--2" style={{ fontSize: 10 }}>Sierra Consulting Group, Inc., while conducting the witnessing of the inspection/test, will not be held responsible and will remain harmless by you (Owner) to damages or incidences to the elevator equipment and/or surround structures resulting from the inspection/test. It is understood that Sierra Consulting Group, Inc. will make recommendations and suggestions based on professional expertise. Sierra Consulting Group, Inc. has made no promises, expressed or implied to the mechanical or electrical integrity of the Vertical Transportation System. Sierra Consulting Group, Inc. does not assume any responsibility for the related equipment or riding passengers. We only witness the testing procedures conducted by the elevator contractor and conduct a visual examination of the equipment with recommendations to bring the systems into compliance with all applicable codes. The client also agrees to shut an elevator down if a dangerous condition is uncovered until proper repairs can be made. Notice of inspection cancellation shall be no less than twenty-four (24) hours. In the event of a cancellation Sierra Consulting Group, Inc. will invoice up to two (2) hours at our hourly rate.
                                                        </p>

                                                        <p className="fs--2" style={{ fontSize: 10 }}>Upon execution of this agreement, Building Owner/Management Company and the Elevator Contractor associated with the premises hereby agrees to indemnify, hold harmless and name as additionally insured Sierra Consulting Group, Inc., its directors, employees and representatives against all liability claims and demands on account of injury to persons including death resulting therefrom and damage to property to the extent arising out of the negligent performance of this contract by the Contractor, employees and agents of the Contractor and Contractor's property with limits of $1 million in general liability, $2 million aggregate and $5 million umbrella coverage. Elevator contractor to provide Sierra as additionally insured on a CG 2010 & CG 2037 forms naming Sierra Consulting Group, Inc., Owner and Property Management. The Contractor shall, at their own expense, defend any and all actions at law brought against Sierra Consulting Group, the Owner and Property Management Company, pay all attorney fees and all other expenses and promptly discharge any judgement arising therefrom. These conditions also shall apply to any of the Contractor's subcontracted operations.
                                                        </p>

                                                        <p className="fs--2" style={{ fontSize: 10 }}>All required DOBNOW signatures must be completed upon immediate request by elevator contractor or Sierra Consulting Group, Inc. Sierra Consulting Group, Inc. will not be liable for late fees or penalties incurred as a result of a late filing. In addition, client agrees to have a signed agreement with their licensed elevator maintenance contractor for the performance of all required category tests.
                                                        </p>

                                                        <p className="fs--2" style={{ fontSize: 10 }}>This proposal is good for a period of up to 90 days from the date of issuance. Proposals not received within the 90 days are subject to change or renegotiation. Sierra Consulting Group, Inc. and the client have the right to cancel this agreement with each other if either breaches any terms, with 30 days written notice. This agreement shall remain in effect from the date signed for a three (3) year period with an annual escalation of three percent (3%) and shall continue thereafter unless written notice is given by either party. Cancellations shall be subject to the immediate payment of all outstanding invoices and fees. Sierra Consulting Group, Inc. has the right to discontinue our work at any time until payments have been made as agreed and we have assurance, satisfactory to us, that subsequent payments will be made when due. Sierra Consulting Group, Inc may assign or transfer its obligations, responsibilities, and rights under this agreement without written notice to Owner/Agent. In the event that the inspection/test exceeds the four (4) hour allotted time per device and/or requires an inspection time/day different from our normal working hours (as noted below), overtime rates may apply.
                                                        </p>

                                                        <p className="fs--2" style={{ fontSize: 10 }}>The following work is not included in our renewal agreement and/or will be billed at our hourly billing rates. Additional proposals will be provided at request of owner/management: testing of firefighters emergency operation, if preformed after hours, testing of door lock monitoring, retesting of elevators due to critical deficiency or equipment failure, and re-inspection of elevators for confirmation on completed affirmation of corrections. Additional fee rates are as follows: <b>straight time (M-F, 8:00am-4:00pm) $250.00 per hour; overtime $375.00 per hour.</b>
                                                        </p>

                                                    </div>
                                                </Scrollbar>

                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="6" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center' }}>
                                            <FormGroup >
                                                <Label>Signature</Label>
                                                <Input
                                                    placeholder="Agent Signature"
                                                    disabled={!canEdit}
                                                    value={agentSignature ? agentSignature : ''}
                                                    onChange={(e) => setAgentSignature(e.target.value)}
                                                />
                                                <small>By Signing above, I agree to Sierra Consulting Group, Inc.'s Terms & Conditions</small>
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                    <Col xs="6" style={{ alignSelf: 'center' }}>
                                        <Alert color="info" style={{ justifyItems: 'center', alignSelf: 'center', height: 135 }}>
                                            <FormGroup >
                                                <Label>Expedite Filing Fee Agreement</Label>
                                                <Row className="mb-3">
                                                    <Col xs="12" md="2">
                                                        <Row xs="12" md="auto">
                                                            <Col xs="6" md="auto">
                                                                Yes
                                                            </Col>
                                                            <Col xs="6" md="auto">
                                                                <Input
                                                                    type="radio"
                                                                    name="expediteFee"
                                                                    placeholder="Yes"

                                                                    disabled={!canEdit}
                                                                    checked={agreedToExpeditingFee === true ? true : false}
                                                                    onChange={({ target }) => setAgreedToExpediteFee(true)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xs="12" md="2">
                                                        <Row xs="12" md="auto">
                                                            <Col xs="6" md="auto">
                                                                No
                                                            </Col>
                                                            <Col xs="6" md="auto">
                                                                <Input
                                                                    type="radio"
                                                                    name="expediteFee"
                                                                    placeholder="No"

                                                                    disabled={!canEdit}
                                                                    checked={agreedToExpeditingFee === false ? true : false}
                                                                    onChange={({ target }) => setAgreedToExpediteFee(false)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                                <small>*Required</small>
                                            </FormGroup>
                                        </Alert>
                                    </Col>
                                </Row>




                                {canEdit === true && <>
                                    <Row>
                                        <Col xs="12" style={{ alignSelf: 'center' }}>
                                            <Row className="mt-3 justify-content-center mb-3">
                                                <Col xs="auto">
                                                    <ReCAPTCHA
                                                        ref={captchaOptions.captchaRef}
                                                        sitekey={captchaOptions.siteKey}
                                                        onChange={handleCaptcha}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={{ alignSelf: 'center' }}>
                                            {showErrorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                            <Row className="mt-3 justify-content-center mb-3">
                                                <Col xs="auto">
                                                    <Button
                                                        type="submit" className="btn-success btn-lg" onClick={handleSubmit}>Submit</Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </>}

                                {proposal.statusId === 3 && <Alert color="success text-center"><h3>Proposal Has Been Submitted!</h3></Alert>}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </>)}

        </>
    );
};

export default NewProposal;
